<template>
  <h1>Queue</h1>
</template>

<script>
export default {
  name: 'Queue'
}
</script>

<style scoped>
</style>
